// We place all translations in 'global' section as we want to follow one structure where we have translations grouped by sections
// As this is shared package we don't want to have translations for specific components and we can put all of them in 'global' section
// NOTE: When adding new sections (new top level keys in this object) make sure to update apps/admin/src/common/data/translation-sections.ts

const translation = {
  global: {
    system: "System",
    url: "URL",
    name: "Name",
    size: "Size",
    notAvailable: "N/A",
    filter: "Filter",
    filters: "Filters",
    selected: "selected",
    card: "Card",
    primary: "Primary",
    loading: "Loading..",
    noFileSelected: "No file selected",
    browserVideoNotSupported: "Your browser does not support the video tag.",
    supportEmail: "support@buildinghub.io",
    goToHomepage: "Go to homepage",
    of: "of",
    results: "results",
    showing: "Showing",
    page: "Page",
    link: "Link",
    supportedFormats: "Supported formats",
    fileUploaded: "File uploaded",
    downloadError: "Something went wrong while trying to convert your file.",
    inactive: "inactive",
    datePlaceholder: "Select date..",
    inputPlaceholder: "Enter..",
    selectPlaceholder: "Select..",
    colorPickerPlaceholder: "Select..",

    // Actions
    viewAction: "View",
    updateAction: "Update",
    deleteAction: "Delete",
    addAction: "Add",
    editAction: "Edit",
    confirmAction: "Confirm",
    replaceAction: "Replace",
    launchAction: "Launch",
    logoutAction: "Logout",
    setAsPrimaryAction: "Set as primary",
    closeAction: "Close",
    saveAction: "Save",

    // Table
    tableLoading: "Looking for the results..",
    tableNoRecordsTitle: "Sorry, no records to display",
    tableNoRecordsDescription:
      "You can try changing filters, page or add new record..",
    tableErrorTitle: "Sorry, we could not fetch the data",
    tableErrorDescription: "We run into the error. Please try again later..",

    // Maintenance
    maintenancePageTitle: "Unable to connect",
    maintenanceSubTitleTitle:
      "It looks like you're having trouble reaching us.",
    maintenanceDescription:
      "This could be due to a temporary network issue. Please try refreshing the page or checking your internet connection. If the problem persists, please reach out to our support team at",

    // Not Found
    notFoundTitle: "You found a place that doesn't exist!",
    notFoundDescription: "Hopefully it will be back soon.",
    copyright: "© {{year}} BuildingHub, All rights reserved",

    // Print
    printThisPage: "Print This Page",
    printExpandAll: "Expand All",
    printCollapseAll: "Collapse All",

    // Uploader
    uploaderDragFilesHere: "Drag your files here, or",
    uploaderClickToBrowse: "click to browse",
    uploaderMaxFileSizeError:
      "Each file can not exceed the maximum limit of {{size}}mb.",
    uploaderError: "An error occurred while uploading files.",

    // Dock
    minimizedItems: "Minimized Items",

    // User Inactive
    accountInactiveTitle: "Your account is inactive!",
    accountInactiveDescription:
      "Please contact administrators for more information about your account.",

    // Text Editor
    textEditorPlaceholder: "Start typing..",
    textEditorErrorImageUpload: "An error ocurred while adding your image.",
  },
};

export default translation;
